<template>
	<div class="detail-card bg-white border">
		<div
			class="card--header d-flex align-items-center pa-2 border-bottom"
			v-on:click="open_detail = !open_detail"
		>
			<div class="font-level-3-bold">Contact Person</div>
			<v-spacer></v-spacer>
			<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
		</div>
		<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
			<v-list class="w-100">
				<template v-for="(data, index) in contactDetail">
					<v-divider v-if="index" :key="'divider' + index" class="my-3"></v-divider>
					<v-list-item :key="index">
						<v-list-item-avatar color="grey lighten-2" size="50">
							<v-img :src="data.avatar" v-if="data.avatar"></v-img>
							<span class="fw-500 grey--text" style="font-size: 20px" v-else>{{
								data.contact_name.slice(0, 2).toUpperCase()
							}}</span>
						</v-list-item-avatar>
						<div style="display: grid; grid-template-columns: 14fr 1fr" class="w-100">
							<div style="display: grid; grid-template-columns: repeat(auto-fill, 30rem)">
								<div class="">
									<v-list-item-title
										class="text-truncate d-flex align-center"
										v-if="data && data.contact_name"
									>
										<v-tooltip top nudge-right="120">
											<template #activator="{ on, attrs }">
												<div
													class="text-truncate cursor-pointer"
													v-on="on"
													v-bind="attrs"
													style="max-width: 22rem"
												>
													{{ data.contact_name }}
												</div>
											</template>
											<span>{{ data.contact_name }}</span>
										</v-tooltip>

										<v-chip
											class="ml-2"
											label
											color="green  white--text"
											x-small
											outlined
											v-if="data.primary == 1"
										>
											Primary
										</v-chip>
									</v-list-item-title>

									<div class="fw-500 mb-1 mr-5">
										<label for="" class="mr-2">Email :</label>
										<template v-if="data && data.email_address">
											<span
												class="blue--text text--darken-4 cursor-pointer"
												v-on:click="copyNumber(data.email_address, 'Email')"
												>{{ data.email_address }}
											</span>
										</template>
										<template v-else> <em class="text-muted">no email</em> </template>
									</div>

									<div class="fw-500 mb-1">
										<!-- <v-icon size="20" color="blue darken-4" v-if="data && data.phone_number">mdi-phone</v-icon> -->
										<label for="" class="mr-2">Phone :</label>
										<template v-if="data && data.phone_number">
											<span
												class="blue--text text--darken-4 cursor-pointer"
												v-on:click="copyNumber(data.phone_number, 'Phone')"
												>{{ data.phone_number }}</span
											>
										</template>
										<template v-else> <em class="text-muted">no phone</em> </template>
									</div>
								</div>
								<div class="" v-if="data && data.contact_name">
									<!-- <v-list-item-title class="mr-1 text-truncate"> -->
									<div class="fw-500 mb-1 mr-5">
										<label style="text-transform: capitalize">Designation :</label>
										<template v-if="data && data.designation">
											<v-chip
												v-if="data && data.designation"
												class="ml-2"
												color="blue white--text"
												x-small
												outlined
												label
												style="height: 16px !important; padding: 4px"
											>
												<span v-if="data && data.designation">{{ data.designation }}</span>
											</v-chip>
										</template>
										<template v-else>
											<em class="text-muted" style="text-transform: lowercase">no designation</em>
										</template>
									</div>
									<div class="fw-500 mb-2">
										<label for="" style="text-transform: capitalize">DID :</label>
										<template v-if="data && data.did_number">
											<span
												class="blue--text text--darken-4 cursor-pointer"
												v-on:click="copyNumber(data.did_number, 'DID')"
												>{{ data.did_number }}</span
											>
										</template>
										<template v-else>
											<em class="text-muted" style="text-transform: lowercase">no did</em>
										</template>
									</div>
									<div class="fw-500 mb-2">
										<label for="" style="text-transform: capitalize">Landline :</label>
										<template v-if="data && data.land_line_number">
											<span
												class="blue--text text--darken-4 cursor-pointer"
												v-on:click="copyNumber(data.land_line_number, 'Landline')"
												>{{ data.land_line_number }}</span
											>
										</template>
										<template v-else>
											<em class="text-muted" style="text-transform: lowercase">no landline</em>
										</template>
									</div>
									<!-- <div class="fw-500 mb-2">
											<label for="" style="text-transform: capitalize">Landline Number :</label>
											<template v-if="data && data.land_line_number">
												<span class="primary--text cursor-pointer" v-on:click="copyNumber(data.land_line_number,'phone')">{{
													data.land_line_number
												}}</span>
											</template>
											<template v-else>
												<em class="text-muted" style="text-transform: lowercase">no landline number</em>
											</template>
										</div> -->
									<!-- </v-list-item-title> -->
								</div>
							</div>
							<v-menu tile left bottom offset-y min-width="120" max-width="180">
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon fab small v-bind="attrs" v-on="on">
										<v-icon color="blue darken-4">mdi-cog</v-icon>
									</v-btn>
								</template>
								<v-list class="pa-2">
									<v-list-item-group>
										<v-list-item>
											<v-list-item-title v-on:click="contactSetPrimary(data.id)" v-if="data.primary != 1">
												Set Primary
											</v-list-item-title>
										</v-list-item>
										<v-list-item>
											<v-list-item-title v-on:click="updateContact(data.id)">Update</v-list-item-title>
										</v-list-item>
										<v-list-item>
											<v-list-item-title
												v-on:click="deleteContact(data.id)"
												v-if="contactDetail.length > 1 && data.primary != 1"
												>Delete</v-list-item-title
											>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-menu>
						</div>
					</v-list-item>
				</template>
			</v-list>
		</div>
		<!-- <ReminderDrawer :drawer="openContact" v-on:close="false"></ReminderDrawer> -->
	</div>
</template>
<script>
//import ContactUpdate from "@/view/module/contacts/customer/components/ContactUpdate.vue";
// import ReminderDrawer from "@/view/module/components/ReminderDrawer.vue"
import { DELETE, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "Contact-Person",
	props: {
		contactDetail: {
			type: Array,
		},
		deleteUrl: {
			type: String,
			default: null,
		},
		primaryUrl: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			open_detail: true,
		};
	},
	methods: {
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `${type} <b>${data}</b> - successfully coppied.` },
			]);
		},
		deleteContact(id) {
			this.$store
				.dispatch(DELETE, { url: `${this.deleteUrl}/${this.relatedId}/${id}` })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Contact deleted successfully." },
					]);
					this.$emit("deleteSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		updateContact(id) {
			this.$emit("open_contact_drawer", id);
		},
		contactSetPrimary(id) {
			this.$store
				.dispatch(PATCH, { url: `${this.primaryUrl}/${this.relatedId}/${id}` })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Contact marked primary." },
					]);
					this.$emit("deleteSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
};
</script>
